import React from "react";
const AuthorizedLayoutComponent = ({ children }) => {
  return (
    <>
      <div className="main-wrapper">
        <div name="mainContent">
          <section className="section">{children}</section>
        </div>
      </div>
    </>
  );
};

export default AuthorizedLayoutComponent;
