import { Routes, Route, Outlet } from "react-router-dom";
import React, { Suspense, useEffect, useMemo, useRef } from "react";
import { LoadPanel } from "devextreme-react/load-panel";
import { LazyLoad } from "./LazyLoad";
import AuthorizedLayoutComponent from "./components/AuthorizedLayoutComponent";
const DefaultMap = LazyLoad("./pages/map/DefaultMap");
const OrderTrackingMap = LazyLoad("./pages/map/OrderTrackingMap");
const VehicleReplay = LazyLoad("./pages/map/VehicleReplay");
function App() {
  const queryParam = new URLSearchParams(window.location.search);
  const key = queryParam.has("key") ? queryParam.get("key") : "";
  const ordernumber = queryParam.has("ordernumber")
    ? queryParam.get("ordernumber")
    : "";
  const routeNo = queryParam.has("routeno") ? queryParam.get("routeno") : 0;
  useMemo(() => {
    sessionStorage.setItem("key", key);
    sessionStorage.setItem("ordernumber", ordernumber);
    sessionStorage.setItem("routeno", routeNo);
  }, [key, ordernumber, routeNo]);

  const isAuthorized =
    (ordernumber && key === process.env.REACT_APP_API_ASLI_AUTH_SECRET_KEY) ===
    true;
  return (
    <>
      {isAuthorized === true ? (
        <AuthorizedLayoutComponent>
          <Routes>
            <Route path="/" element={<AuthorizedRouteWrapper />}>
              <Route
                path="/"
                element={routeNo ? <VehicleReplay /> : <OrderTrackingMap />}
              />
              <Route
                path="*"
                element={routeNo ? <VehicleReplay /> : <OrderTrackingMap />}
              />
              <Route
                path="/track"
                element={routeNo ? <VehicleReplay /> : <OrderTrackingMap />}
              />
              <Route path="/summary" element={<OrderTrackingMap />} />

              {/* <Route path="/" element={<OrderTrackingMap />} />
              <Route path="*" element={<OrderTrackingMap />} />
              <Route path="/track" element={<OrderTrackingMap />} />
              <Route path="/summary" element={<OrderSummary />} /> */}
            </Route>
          </Routes>
        </AuthorizedLayoutComponent>
      ) : (
        <Routes>
          <Route path="*" element={<DefaultMap />} />
        </Routes>
      )}
    </>
  );
}

function AuthorizedRouteWrapper() {
  return (
    <>
      <Suspense
        fallback={
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: "#divLoader" }}
            visible={true}
            showIndicator={true}
            shading={true}
            showPane={true}
            hideOnOutsideClick={false}
          />
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
}
export default App;
